.textFieldClass >  div >input{
    padding: 17px !important;
    border-radius: 5px !important;
}
.textFieldClass >  div >textarea{
    padding: 17px !important;
    border-radius: 5px !important;
}

.textFieldClass >  div {
    border-radius: 15px !important;
    width:100% !important;
}
.textFieldClass >  div::after{
    border-bottom: 2px solid #FDB515 !important;
}
.textFieldClass >  div::before{
    border-bottom: none !important;
}