body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.inputcontrols{
  background-color: white !important;
  /* width: 212px !important; */
 border-top-left-radius: 8px !important;
 border-bottom-left-radius: 8px !important;

}
.inputcontrols>div>input{
 padding: 13px !important;
}
.inputcontrols >  div::after{
  border-bottom: 2px solid #FDB515 !important;
}
.inputcontrols >  div::before{
  border-bottom: none !important;
}
.blueSection > div{
  text-align: center !important;
  background-color: #21668E;
  color: aliceblue ;
  padding: 17px;
  width: 100% !important;
  border-radius: 3px !important;
  
  }
  .inputcontrolings >  div > div > div > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root ::after{
    border-bottom: 2px solid #FDB515 !important;
}
.selectControl > div{
padding: 13px !important;
}
.selectcontroling > div >div> label{
  color: lightgrey !important;
}



.datepickerControl .MuiInputBase-root {
  border-radius: 15px;
  width: 100%;
}